.badge {
  --bs-badge-font-size: 0.75rem;
  --bs-badge-border-radius: 3rem;
  --bs-badge-padding-y: 0.25rem;
  --bs-badge-padding-x: 0.5rem;
  font-weight: 500;
  color: #7a7a7a;
  background: #f0f0f0;
}
.badge-info {
  color: #00aed6;
  background: #e5f7fb;
}
.badge-success {
  color: #1da84a;
  background: #e9f4ec;
}
.badge-danger {
  color: #ff3a3a;
  background: #ffebeb;
}
